.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.title {
  padding-top: 20px;
}

.board {
  margin: 0 auto;
  display:inline-block;
}

.world {
  display: grid;
  grid-template-columns: repeat(75, 10px);
}

.cell:hover {
  background-color: #bcbcc1 !important; 
  cursor: pointer;
}

.control {
  padding-top: 15px;
}


